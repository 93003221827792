import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Button } from "../src/gatsby-theme-docz/components/Button/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "autohäuser"
    }}>{`Autohäuser`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Kunde`}</th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`GRUMA Mercedes Autohaus, Grimma`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Mercedes Autohaus WUSSLER, Großbardau`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S/L`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`105 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Autohaus/Fitneßcenter BINDER, Döbeln`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`70 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Autohaus BLÜMEL/WACKE, Oschatz`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`60 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Autohaus KAPUST, Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`45 T€`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Werkstatt/Lackierei WAGNER, Leipzig`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`H/S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50 T€`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Legende: H=Heizung, S=Sanitär, L=Lüftungsinstallation, K=Kälte- bzw. Klimainstallationen, MSR=Regeltechnische Anlagen`}</strong></p>
    <Button mdxType="Button" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      